<template>
  <div
    class="d-flex align-items-center w-hover-color-secondary pointer"
    @click.prevent.stop="toggleStarred()"
  >
    <span v-if="starText && !isStarred" class="mr-2 text-right">{{
      starText
    }}</span>
    <span v-else-if="unstarText && isStarred" class="mr-2 text-right">{{
      unstarText
    }}</span>

    <slot name="icon">
      <span class="z-icon-wrapper border">
        <svg-heart-icon
          class="-heart"
          :class="{ 'w-color-secondary': isStarred || highlighted }"
        />
      </span>
    </slot>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

export default {
  components: {
    "svg-heart-icon": () => import("/assets/icons/heart.svg?inline"),
  },

  props: {
    model: { default: "course" },
    id: { required: true },
    starred: { required: true },
    starText: { default: "" },
    unstarText: { default: "" },
    starredText: { default: "" },
    unstarredText: { default: "" },
    highlighted: { default: false },
  },

  data() {
    return {
      isStarred: this.starred,
      starredTextDisplay: "",
      unstarredTextDisplay: "",
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },

  async created() {
    // Set default values with translation
    this.starredTextDisplay = this.starredText
      ? this.starredText
      : this.$t(`Kurz bol pridaný medzi Moje kurzy!`);
    this.unstarredTextDisplay = this.unstarredText
      ? this.unstarredText
      : this.$t(`Kurz bol odstránený z Mojich kurzov!`);
  },

  methods: {
    async toggleStarred() {
      if (!this.isLoggedIn) {
        localStorage.setItem("LAST_FROM_ROUTE", window.location.pathname);
        this.EventBus.$emit("locked-content-accessed");
        return;
      }

      try {
        await this.$store.dispatch("auth/updateUser", {
          [this.model === "course" ? "favoriteCourses" : "favoritePaths"]: {
            [this.isStarred ? "disconnect" : "connect"]: [
              {
                id: this.id,
              },
            ],
          },
        });

        this.isStarred = !this.isStarred;

        const toastText = this.isStarred
          ? this.starredTextDisplay
          : this.unstarredTextDisplay;

        const toastId = this.isStarred ? "starred" : "unstarred";
        this.$toast.success(toastText, toastId);
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.z-pointer:hover {
  cursor: pointer;
}
a {
  &:hover {
    text-decoration: none;
  }
}
</style>
